<template>
  <div>
    <b-card>
      <div class="d-flex align-items-start filter-form">
        <div>
          <b-form
            class="mb-2"
            inline
          >

            <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.searchType"
              :options="searchOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Search Type</b-form-select-option>
              </template>
            </b-form-select>

            <b-form-input
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.searchValue"
              placeholder="Search Input"
            ></b-form-input>
            <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.by_logged"
              :options="InfluencerOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Select Influencer</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.inf_type"
              :options="Influencertype"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Influencer Type</b-form-select-option>
              </template>
            </b-form-select>
            <b-button
              pill
              class="mt-lg-0 mt-md-2 mr-sm-2"
              variant="primary"
              @click="search()"
            >Search</b-button>
            <b-button
              pill
              class="mt-lg-0 mt-md-2 mr-sm-2"
              variant="primary"
              @click="resetFilter()"
            >Reset</b-button>
            <b-overlay
              class="d-inline-block"
              :show="exportLoading"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              @hidden="!exportLoading"
            >
              <b-button
                pill
                class="mt-lg-0 mt-md-2 mr-sm-2"
                variant="primary"
                @click="exportdata()"
              >Export</b-button>
            </b-overlay>
          </b-form>
        </div>

      </div>
      <hr />
      <div
        class="d-block text-center"
        v-if="!tabledata.length && !loading"
      >
        <img
          src="@/assets/images/no-data.png"
          width="150"
        />
        <h5 class="mt-2">No Influencers Found</h5>
      </div>
      <b-table
        :items="tabledata"
        :fields="fields"
        id="tabledata"
        :busy="loading"
        head-variant="light"
        responsive
        hover
        v-else
      >
        <template #table-busy>
          <div class="text-center text-primary mt-5 mb-1">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(influencer)="data">
          <div class="meta d-flex justify-content-start align-items-center">
            <img
              :src="data.item.avatar"
              v-if="data.item.avatar"
            />
            <b-avatar
              size="lg"
              v-else
              :src="`https://ui-avatars.com/api/?background=F54153&color=fff&name=${data.item.name ? data.item.name  : 'NA'}`"
            ></b-avatar>
            <p>
              <strong>{{ data.item.name }}</strong> <br />
              <b-badge
                v-if="data.item.inf_type === 0"
                pill
                style="background:green"
              >{{ data.item.inf_type | infDetail }}</b-badge>
              <b-badge
                v-if="data.item.inf_type === 1"
                pill
                style="background:rgb(203 141 77)"
              >{{ data.item.inf_type | infDetail }}</b-badge>
              <b-badge
                v-if="data.item.inf_type === 2"
                pill
                variant="info"
              >{{ data.item.inf_type | infDetail }}</b-badge>
              <b-badge
                v-if="data.item.inf_type === 3"
                pill
                style="background:#b14c4c"
              >{{ data.item.inf_type | infDetail }}</b-badge>
            </p>
          </div>
        </template>
        <!-- <template #cell(mobile)="data">
          <div style="width: 150px;">
            {{ data.item.mobile }}
          </div>
        </template> -->
        <template #cell(channel)="data">
          <div style="width: 150px;">
            <h6 v-if="data.item.channelCount"><b-badge
                href="#"
                variant="primary"
                pill
                @click="viewinfDetail(data.item)"
              ><b-icon
                  icon="eye-fill"
                  class="mr-1"
                ></b-icon>View</b-badge></h6>
            <h6 v-else><b-badge
                href="#"
                style="background:rgb(187 68 187)"
                pill
                @click="addChannel(data.item)"
              ><b-icon
                  icon="plus-circle-fill"
                  class="mr-1"
                ></b-icon>Add</b-badge></h6>
          </div>
        </template>
        <template #cell(feedback)="data">
          <div class="d-flex justify-content-start">
            <b-icon
              font-scale="2.0"
              :icon="data.item.feedback  ? 'check2-circle' : 'x-circle'"
              :variant="data.item.feedback ? 'success' :'danger' "
            ></b-icon>
          </div>
        </template>
        <template #cell(email)="data">
          <div style="width: 150px;">
            {{ data.item.email }}
          </div>
        </template>
        <template #cell(account_name)="data">
          <div style="width: 150px;">
            {{ data.item.account_name }}
          </div>
        </template>
        <template #cell(pan)="data">
          <div style="width: 150px;">
            {{ data.item.pan ? data.item.pan.toUpperCase() : null }}
          </div>
        </template>
        <template #cell(add_time)="data">
          {{ data.value | dateFormat }}
        </template>
        <template #cell(action)="data">
          <b-dropdown
            variant="outline-white"
            no-caret
          >
            <template #button-content>
              <b-icon icon="three-dots-vertical"></b-icon>
            </template>
            <b-dropdown-item
              target="_blank"
              @click="viewFeedback(data.item)"
            >
              <b-icon icon="chat-text"></b-icon>
              Feedback
            </b-dropdown-item>
            <b-dropdown-item @click="editmodal(data.item)">
              <b-icon icon="pencil-square"></b-icon>
              Edit
            </b-dropdown-item>
            <b-dropdown-item @click="deleteInfluencer(data.item.uuid)">
              <b-icon icon="trash"></b-icon>
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination
        v-if="total > 20 && !loading"
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        align="center"
        pills
        @input="handlePageClick"
      ></b-pagination>
      <b-modal
        title="Edit Influencer"
        ref="editModal"
        hide-footer
        :no-close-on-backdrop="true"
        centered
      >
        <validation-observer ref="simpleRules">
          <b-form>

            <b-form-group label="Influencer Name">
              <validation-provider
                #default="{ errors }"
                name="Influencer Name"
                :rules="{required: true, regex: /^[a-zA-Z\s]*$/}"
              >
                <b-form-input
                  v-model="editInfluencer.name"
                  placeholder="Influencer Name"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Mobile No">
              <validation-provider
                #default="{ errors }"
                name="Mobile No"
                :rules="{ required: true, regex: /^[0-9]{10}$/ }"
              >
                <b-form-input
                  v-model="editInfluencer.mobile"
                  :disabled="editInfluencer.by_logged ? true : false"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Mobile Number"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="email"
              >
                <b-form-input
                  v-model="editInfluencer.email"
                  placeholder="Email Address"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Pan No">
              <validation-provider
                #default="{ errors }"
                name="Pan"
                :rules="{regex: /^[a-zA-Z0-9]{10}$/ }"
              >
                <b-form-input
                  v-model="editInfluencer.pan"
                  placeholder="Enter Pan Number"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Account Name">
              <validation-provider
                #default="{ errors }"
                name="Account Name"
                :rules="{regex: /^[a-zA-Z\s]*$/ }"
              >
                <b-form-input
                  v-model="editInfluencer.account_name"
                  placeholder="Account Name"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Bank Name">
              <validation-provider
                #default="{ errors }"
                name="Bank Name"
                :rules="{regex: /^[a-zA-Z\s]*$/ }"
              >
                <b-form-input
                  v-model="editInfluencer.bank_name"
                  placeholder="Bank Name"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Account Number">
              <validation-provider
                #default="{ errors }"
                name="Account Number"
                :rules="{regex: /^[0-9]{10,20}$/ }"
              >
                <b-form-input
                  v-model="editInfluencer.account_number"
                  placeholder="Account Number"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="IFSC">
              <validation-provider
                #default="{ errors }"
                name="IFSC"
                :rules="{regex: /^[a-zA-Z0-9]{10,15}$/}"
              >
                <b-form-input
                  v-model="editInfluencer.ifsc"
                  placeholder="IFSC Number"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Address">
              <validation-provider
                #default="{ errors }"
                name="Address"
              >
                <b-form-textarea
                  v-model="editInfluencer.address"
                  placeholder="Address"
                  :state="errors.length > 0 ? false : null"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="d-flex justify-content-end mt-4">
              <b-button
                pill
                class="mr-2"
                variant="secondary"
                @click="cancelEdit"
              >Cancel</b-button>
              <b-button
                pill
                type="submit"
                variant="primary"
                @click.prevent="updateInfluencer"
              >Update</b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- Channel View Modal  -->
      <b-modal
        :title="channelName"
        ref="viewModal"
        :no-close-on-backdrop="true"
        hide-footer
      >
        <div
          class="d-block text-center"
          v-if="!infDetaildata.length && !loading2"
        >
          <img
            src="@/assets/images/no-data.png"
            width="100"
          />
          <h5 class="mt-2">No Channels Found</h5>
        </div>
        <b-table
          :items="infDetaildata"
          sticky-header="true"
          :fields="inflfields"
          :busy="loading2"
          head-variant="light"
          responsive
          hover
          v-else
        >
          <template #table-busy>
            <div class="text-center text-primary mt-5 mb-1">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(name)="data">
            <div class="d-flex">
              <b-icon
                variant="primary"
                :icon="`${data.item.media_type === 0 ? 'youtube' : 'instagram'}`"
                class="text-danger"
                font-scale="1.5"
              ></b-icon>
              <p>
                {{ data.item.media_type === 0 ? data.item.channel_name :  data.item.insta_name }}</p>
              <b-icon
                v-b-tooltip.hover.v-success="'Authorized Channel'"
                v-if="data.item.verify"
                variant="success"
                icon="patch-check-fill"
                class="ml-2"
                font-scale="1"
              ></b-icon>
            </div>
          </template>
          <template #cell(link)="data">
            <a
              :href="data.item.media_type === 0 ? data.item.channel_link :  data.item.insta_link"
              target="_blank"
            >
              {{ data.item.channel_link || data.item.insta_link ? 'Channel Link' : 'NA'}}
            </a>
          </template>
        </b-table>
      </b-modal>
      <!-- Add Channel Modal -->
      <b-modal
        title="Add Channel"
        ref="mediaModal"
        :no-close-on-backdrop="true"
        hide-footer
      > <validation-observer ref="mediaRules">
          <b-form>
            <b-form-group label="Please Select Media">
              <validation-provider
                #default="{ errors }"
                name="Select Media"
                rules="required"
              >
                <b-form-checkbox-group
                  v-model="addMedia.mediaId"
                  @change="changeMedia"
                  :options="mediaType"
                  value-field="value"
                  text-field="label"
                ></b-form-checkbox-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="YouTubeLink"
              v-if="addMedia.mediaId && (addMedia.mediaId[0] === 0 || addMedia.mediaId[1] === 0)"
            >
              <validation-provider
                #default="{ errors }"
                name="YouTubeLink"
                :rules="{ required: true, regex: /^https:\/\/(www.youtube.com\/channel\/)([^ /]+)/ }"
              >
                <b-form-input
                  v-model="addMedia.channel_link"
                  placeholder="https://www.youtube.com/channel/channelid"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="InstagramLink"
              v-if="addMedia.mediaId && (addMedia.mediaId[0] === 1 || addMedia.mediaId[1] === 1)"
            >
              <validation-provider
                #default="{ errors }"
                name="InstagramLink"
                :rules="{ required: true, regex: /^https:\/\/www.instagram.com\/([^ /]+)/ }"
              >
                <b-form-input
                  v-model="addMedia.insta_link"
                  placeholder="https://www.instagram.com/username"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="d-flex justify-content-end">
              <b-overlay
                class="d-inline-block"
                :show="loading2"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                @hidden="!loading2"
              >
                <b-button
                  pill
                  type="submit"
                  variant="primary"
                  @click.prevent="submitMedia"
                >Submit</b-button>
              </b-overlay>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- Add Feedback Modal -->
      <b-modal
        title="Influencer Feedback"
        ref="feedbackModal"
        hide-footer
        :no-close-on-backdrop="true"
      >
        <validation-observer ref="feedRules">
          <b-form>

            <b-form-group label="Feedback">
              <validation-provider
                #default="{ errors }"
                name="Influencer Feedback"
                rules="required"
              >
                <b-form-textarea
                  v-model="addFeedback.feedback"
                  placeholder="Influencer Feedback"
                  rows="4"
                  :state="errors.length > 0 ? false : null"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="d-flex justify-content-end">
              <b-overlay
                class="d-inline-block"
                :show="loading2"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                @hidden="!loading2"
              >
                <b-button
                  pill
                  type="submit"
                  variant="primary"
                  @click.prevent="submitFeed"
                >Submit</b-button>
              </b-overlay>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import constantData from '@/helpers/constant'
import { influencerList, influencerDelete, influencerUpdate, influencerDetail, feedback, addMedia } from '@/api/influencer'
export default {
  data () {
    return {
      perPage: 20,
      currentPage: 1,
      editModal: false,
      feedbackModal: false,
      mediaModal: false,
      tabledata: [],
      infDetaildata: [],
      loading: true,
      loading2: false,
      exportLoading: false,
      total: 0,
      channelName: null,
      filter: {
        searchType: null,
        searchValue: null,
        inf_type: null,
        by_logged: null,
        p: 1,
        size: 20
      },
      mediaType: [{
        value: 0,
        label: 'YouTube'

      }, {
        value: 1,
        label: 'Instagram'
      }],
      addMedia: {},
      addFeedback: {},
      fields: [
        { key: 'index', label: 'Sr.' },
        { key: 'influencer', label: 'Influencer Name' },
        { key: 'channel', label: 'Channel' },
        { key: 'mobile', label: 'Mobile' },
        { key: 'email', label: 'Email' },
        { key: 'pan', label: 'Pan' },
        { key: 'account_name', label: 'Account Name' },
        { key: 'add_time', label: 'Add Time' },
        { key: 'feedback', label: 'Follow Up' },
        'action'
      ],
      inflfields: [
        { key: 'index', label: 'Sr.' },
        { key: 'name', label: 'Channel Name' },
        { key: 'link', label: 'Channel Url' }
      ],
      editInfluencer: {
      },
      Influencertype: constantData.infList.map(item => {
        return { value: item.value, text: item.label }
      }),
      searchOptions: [
        { value: 'name', text: 'Name' },
        { value: 'contact', text: 'Contact' },
        { value: 'email', text: 'Email' }
      ],
      InfluencerOptions: [
        { value: 0, text: 'Admin Influencer' },
        { value: 1, text: 'Signup Influencer' }
      ]
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    exportdata () {
      this.exportLoading = true
      var influencerWS = XLSX.utils.json_to_sheet(this.tabledata)
      var wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, influencerWS, 'InfluencerData')
      setTimeout(() => {
        XLSX.writeFile(wb, 'Influencer.xlsx')
        this.exportLoading = false
      }, 2000)
    },
    resetFilter () {
      this.filter = {
        searchType: null,
        searchValue: null,
        by_logged: null,
        inf_type: null,
        p: 1,
        size: 20
      }
      this.currentPage = 1
      this.getData()
    },
    addChannel (item) {
      this.addMedia = {
        uuid: item.uuid,
        inf_type: item.inf_type,
        mobile: item.mobile,
        email: item.email
      }
      this.$refs.mediaModal.show()
    },
    async viewinfDetail (item) {
      this.channelName = item.name
      const data = {
        uuid: item.uuid
      }
      this.$refs.viewModal.show()
      this.loading2 = true
      const response = await influencerDetail(data)
      if (response.succ) {
        this.infDetaildata = response.data
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
      this.loading2 = false
    },
    editmodal (item) {
      this.editInfluencer = {
        mobile: item.mobile,
        email: item.email,
        name: item.name,
        uuid: item.uuid,
        ifsc: item.ifsc ? item.ifsc.toUpperCase() : null,
        account_number: item.account_number,
        bank_name: item.bank_name,
        account_name: item.account_name,
        address: item.address,
        by_logged: item.by_logged,
        inf_type: item.inf_type,
        pan: item.pan ? item.pan.toUpperCase() : null
      }
      this.$refs.editModal.show()
    },
    viewFeedback (item) {
      this.addFeedback = {
        feedback: item.feedback,
        uuid: item.uuid
      }
      this.$refs.feedbackModal.show()
    },
    submitFeed () {
      this.$refs.feedRules.validate().then(async success => {
        if (success) {
          try {
            const data = {
              actionUser: window.localStorage.getItem('user-name'),
              uuid: this.addFeedback.uuid,
              feedback: this.addFeedback.feedback
            }
            this.loading2 = true
            const response = await feedback(data)
            if (response.succ) {
              this.$bvToast.toast('Feedback Added', {
                title: 'Add',
                variant: 'success',
                autoHideDelay: 2000,
                solid: true
              })
              const index = this.tabledata.findIndex(a => a.uuid === this.addFeedback.uuid)
              this.tabledata[index].feedback = this.addFeedback.feedback
              this.$refs.feedbackModal.hide()
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Feedback error!',
                variant: 'danger',
                solid: true
              })
            }
            this.loading2 = false
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    changeMedia (checked) {
      console.log('checked', checked)
    },
    submitMedia () {
      this.$refs.mediaRules.validate().then(async success => {
        if (success) {
          try {
            let channelId = null
            let instaId = null
            if (this.addMedia.channel_link) {
              const id = this.addMedia.channel_link.replace('https://www.youtube.com/channel/', '')
              channelId = id.replace(/[^A-Za-z0-9_-].*$/g, '') // remove other than A-Za-z0-9_-
            }
            if (this.addMedia.insta_link) {
              const id2 = this.addMedia.insta_link.replace('https://www.instagram.com/', '')
              instaId = id2.replace(/[/].*$/g, '') // remove after slash
            }
            const data = {
              actionUser: window.localStorage.getItem('user-name'),
              uuid: this.addMedia.uuid,
              insta_link: (this.addMedia.mediaId[0] === 1 || this.addMedia.mediaId[1] === 1) ? this.addMedia.insta_link : null,
              channel_link: (this.addMedia.mediaId[0] === 0 || this.addMedia.mediaId[1] === 0) ? this.addMedia.channel_link : null,
              channelId: (this.addMedia.mediaId[0] === 0 || this.addMedia.mediaId[1] === 0) ? channelId : null,
              instaId: (this.addMedia.mediaId[0] === 1 || this.addMedia.mediaId[1] === 1) ? instaId : null,
              inf_type: this.addMedia.inf_type,
              mobile: this.addMedia.mobile,
              email: this.addMedia.email
            }
            this.loading2 = true
            const response = await addMedia(data)
            if (response.succ) {
              this.$bvToast.toast('Channel Added', {
                title: 'Add',
                variant: 'success',
                autoHideDelay: 2000,
                solid: true
              })
              const index = this.tabledata.findIndex(a => a.uuid === this.addMedia.uuid)
              this.tabledata[index].channelCount = 1
              this.$refs.mediaModal.hide()
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Channel add error!',
                variant: 'danger',
                solid: true
              })
            }
            this.loading2 = false
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    cancelEdit () {
      this.$refs.editModal.hide()
    },
    handlePageClick (pageNumber) {
      this.filter.p = pageNumber
      this.getData()
    },
    search () {
      if (this.filter.searchValue && !this.filter.searchType) {
        return this.$bvToast.toast('Please select search type', {
          title: 'Search error!',
          variant: 'danger',
          solid: true
        })
      }
      this.currentPage = 1
      this.filter.p = 1
      this.getData()
    },
    async getData () {
      this.loading = true
      const response = await influencerList(this.filter)
      if (response.succ) {
        this.total = response.totaluser
        this.tabledata = response.data
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
      this.loading = false
    },
    async deleteInfluencer (uuid) {
      try {
        this.$bvModal.msgBoxConfirm('Are are you sure want to delete this Influencer?', {
          title: 'Please Confirm',
          cancelVariant: 'danger',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          size: 'sm',
          buttonSize: 'sm',
          hideHeaderClose: false,
          centered: true
        }).then(async (value) => {
          const data = {
            actionUser: window.localStorage.getItem('user-name'),
            uuid: uuid,
            is_active: 2
          }
          if (value) {
            const response = await influencerDelete(data)
            if (response.succ) {
              this.$bvToast.toast('Influencer deleted successfully', {
                title: 'Deleted',
                variant: 'success',
                autoHideDelay: 2000,
                solid: true
              })
              this.tabledata.splice(this.tabledata.findIndex(a => a.uuid === uuid), 1)
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Delete error!',
                variant: 'danger',
                solid: true
              })
            }
          }
        }).catch((error) => {
          console.log('cancel delete. error', error)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async updateInfluencer () {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            this.$bvModal.msgBoxConfirm('Are are you sure want to update this Influencer?', {
              title: 'Please Confirm',
              cancelVariant: 'danger',
              okVariant: 'primary',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              size: 'sm',
              buttonSize: 'sm',
              hideHeaderClose: false,
              centered: true
            }).then(async (value) => {
              const data = {
                actionUser: window.localStorage.getItem('user-name'),
                uuid: this.editInfluencer.uuid,
                email: this.editInfluencer.email,
                mobile: this.editInfluencer.mobile,
                name: this.editInfluencer.name,
                pan: this.editInfluencer.pan ? this.editInfluencer.pan.toUpperCase() : null,
                address: this.editInfluencer.address,
                bank_name: this.editInfluencer.bank_name,
                ifsc: this.editInfluencer.ifsc ? this.editInfluencer.ifsc.toUpperCase() : null,
                account_number: this.editInfluencer.account_number,
                account_name: this.editInfluencer.account_name,
                inf_type: this.editInfluencer.inf_type
              }
              if (value) {
                const response = await influencerUpdate(data)
                if (response.succ) {
                  this.$bvToast.toast('Influencer updated successfully', {
                    title: 'Updated',
                    variant: 'success',
                    autoHideDelay: 2000,
                    solid: true
                  })
                  this.getData()
                  this.$refs.editModal.hide()
                } else {
                  this.$bvToast.toast(response.msg, {
                    title: 'Update error!',
                    variant: 'danger',
                    solid: true
                  })
                }
              }
            }).catch((e) => {
              console.log('cancel update.', e)
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    async detailData () {
      this.loading2 = true
      const response = await influencerDetail(this.filter)
      if (response.succ) {
        this.total = response.totaluser
        this.tabledata = response.data
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
      this.loading2 = false
    }
  }
}
</script>

<style datad>
  .card-body >>> .table {
    font-size: 14px;
  }
  .table td p {
    margin: 0 0 0 10px;
    line-height: 18px;
  }
  .table td .meta p strong {
    text-decoration: none;
    /* color: #4c62e3; */
    text-transform: uppercase;
  }
  .table td img,
  .b-avatar {
    height: 45px;
    width: 45px;
    border-radius: 50%;
  }
  .table td p small {
    /* color: #f54153; */
    font-size: 13px;
    text-transform: capitalize;
  }
</style>
